<template>
	<div class="article-details-page" id="articleDetailsPage">
		<div class="top-container cs-mbx">
			<b-button @click="navigateToSearch()" variant="function" class="backButton">
				Zurück zur Übersicht
			</b-button>
		</div>

		<img :src="headlineImage" class="headlineImage cs-mbl" v-if="headlineImage" />

		<div class="grid-container cs-mbm">
			<div class="date-wrapper light-text-color column-12">
				<span v-if="dates.createdAt !== ''" class="createdAt">
          {{ getLocalDate(dates.createdAt) | dateFormat('D. MMMM YYYY') }}
				</span>
			</div>
		</div>

		<h1 class="headline cs-h1">{{ headline }}</h1>

		<div class="author-wrapper light-text-color cs-mbx">
			<span v-if="author.trim() !== ''" class="author">von</span>
			<span v-if="author.trim() !== ''" class="author">&nbsp;{{ author }}</span>
			<span v-if="author.trim() !== '' && readingTime !== ''" class="separator">&#124;</span>
			<span v-if="readingTime !== ''" class="readingTime">{{ readingTime }}&nbsp;Min. Lesezeit</span>
			<span v-if="tags.trim() !== ''" class="separator">&#124;</span>
			<span v-if="tags.trim() !== ''" class="tags">{{
				tags
					.split(',')
					.splice(0, 5)
					.join(',')
			}}</span>
		</div>

		<div class="articleContent firstContainer">
			<div
				v-for="(html, index) in articleHtmlArray.slice(0, sliderIndexPositionInHtml)"
				v-bind:key="index"
				v-html="html"
			></div>
		</div>

		<div v-if="gallerySliderItems && gallerySliderItems.length > 0" class="articleContent secondContainer">
			<div class="gallery-slider cs-mbx">
				<VueSlickCarousel v-bind="settings">
					<div v-for="(item, index) in gallerySliderItems" :key="index">
						<div class="gallery-slider-item">
							<div class="image-container">
								<img
									v-if="item.imgsrc && item.imgsrc !== ''"
									:src="item.imgsrc"
									:alt="item.imgalt"
									:title="item.imgalt"
									itemprop="image"
									class="centered-image image-slider"
								/>
							</div>
							<span class="text">{{ item.text }}</span>
						</div>
					</div>
				</VueSlickCarousel>
			</div>
		</div>

		<div class="articleContent thirdContainer">
			<div
				v-for="(html, index) in articleHtmlArray.slice(sliderIndexPositionInHtml)"
				v-bind:key="index"
				v-html="html"
			></div>
		</div>

		<div
			v-if="authorProfilePicture.url.trim() !== '' || authorProfileText.trim() !== ''"
			class="author-box grid-container cs-mtxxl cs-mbxxl"
		>
			<div v-if="authorProfilePicture.url.trim() !== ''" class="column-3 column-xs-12 author-img-wrapper">
				<div class="image-box">
					<img :src="authorProfilePicture.url" :title="authorProfilePicture.title" :alt="authorProfilePicture.title" />
				</div>
			</div>
			<div
				:class="{
					'column-9': authorProfilePicture.url.trim() !== '',
					'column-12': authorProfilePicture.url.trim() === '',
				}"
				class=" column-xs-12 author-info-wrapper"
			>
				<h2 class="headline cs-h2">{{ author }}</h2>
				<span v-if="author.trim() !== companyName.trim()" class="light-text-color cs-mbm">{{ companyName }}</span>
				<p class="info-text">{{ authorProfileText }}</p>
			</div>
		</div>

		<div v-if="similarArticles && similarArticles.length > 0" class="similar-articles-wrapper cs-mtxxl">
			<h2 class="headline cs-h2 center cs-mbl">Das könnte Sie auch interessieren</h2>

			<div class="articleList styleGrid grid-container">
				<div :key="article.id" v-for="article in similarArticles" class="articleListItem column-4 column-xs-12 cs-mbl">
					<ArticleListItem :article="article"></ArticleListItem>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import MarkdownIt from 'markdown-it';
import ArticleListItem from './ArticleListItem';
import VueSlickCarousel from 'vue-slick-carousel';

const config = require('../../config.js');

export default {
	name: 'ArticleDetailsPage',
	components: { ArticleListItem, VueSlickCarousel },
	data() {
		return {
			headline: '',
			headlineImage: '',
			articleHtmlArray: [],
			sliderIndexPositionInHtml: 1,
			gallerySliderItems: [],
			author: '',
			companyName: '',
			authorProfilePicture: { url: '', title: '' },
			authorProfileText: '',
			dates: { createdAt: '', updatedAt: '' },
			readingTime: '',
			similarArticles: '',
			tags: '',
			settings: {
				infinite: true,
				waitForAnimate: false,
				slidesToShow: 3,
				slidesToScroll: 1,
				swipe: true,
				responsive: [
					{
						breakpoint: 767,
						settings: {
							slidesToShow: 2,
						},
					},
				],
			},
		};
	},
	created() {
		this.getArticle();
	},
	watch: {
		$route: 'getArticle',
	},
	methods: {
		getArticle() {
			setTimeout(function() {
				window.scrollTo(0, 0);
			}, 400);

			const companyId = this.$store.getters.getCompanyId;
			const apiKey = this.$store.getters.getApiKey;
			const articleId = this.$route.params.id;
			const url = `${config.apiURL}/${config.stage}/v1/company/${companyId}/articledata/${articleId}`;

			axios
				.get(url, {
					headers: {
						'x-api-key': apiKey,
						'x-access-type': 'storefront',
						encrypted: true,
					},
				})
				.then((result) => {
					if (result.data.article.redirectParam) {
						this.$router.push({ name: 'article', params: { id: result.data.article.fields.urlSlug } }); 
					}
					this.parseArticle(result.data.article);
				});
		},
		parseArticle(article) {
			this.headline = article.fields.headline;
			this.companyName = article.fields.author.fields.companyName ? article.fields.author.fields.companyName : '';
			this.author =
				article.fields.author.fields.firstName && article.fields.author.fields.firstName.trim() !== ''
					? article.fields.author.fields.firstName
					: '';
			this.author += article.fields.author.fields.lastName
				? article.fields.author.fields.firstName && article.fields.author.fields.firstName.trim() !== ''
					? ' ' + article.fields.author.fields.lastName
					: article.fields.author.fields.lastName
				: '';
			if (this.author.trim() === '') {
				this.author = this.companyName;
			}
			this.authorProfilePicture.url =
				article.fields.author.fields.profilePicture && article.fields.author.fields.profilePicture.fields.file.url
					? article.fields.author.fields.profilePicture.fields.file.url
					: '';
			this.authorProfilePicture.title =
				article.fields.author.fields.profilePicture && article.fields.author.fields.profilePicture.fields.title
					? article.fields.author.fields.profilePicture.fields.title
					: '';
			this.authorProfileText = article.fields.author.fields.profileText ? article.fields.author.fields.profileText : '';
			this.dates.createdAt = article.sys.createdAt ? article.sys.createdAt : '';
			this.dates.updatedAt = article.sys.updatedAt ? article.sys.updatedAt : '';
			this.readingTime = article.fields.readingTime ? article.fields.readingTime : '';
			this.similarArticles = article.fields.similarArticles;
			this.tags = article.fields.tags && article.fields.tags.length > 0 ? article.fields.tags.join(', ') : '';

			if (article.fields.headlineImage) {
				this.headlineImage = article.fields.headlineImage.fields.file.url;
			}
			this.articleHtmlArray = this.parseMarkdown(article.fields.content);
		},
		parseMarkdown(content) {
			const md = new MarkdownIt({
				quotes: '„“',
				html: true,
			});
			let html = md.render(content);
			let htmlArray = this.splitContentForGallerySlider(html);

			for (let i = 0; i < htmlArray.length; i++) {
				let regex = new RegExp('&lt;--left--{(.+?)}--text--{(.+?)}--&gt;', 'gms');
				htmlArray[i] = htmlArray[i].replace(
					regex,
					'<div class="grid-container"><div class="grid-column-6-left">$1</div><div class="grid-column-6-right">$2</div></div>'
				);

				regex = new RegExp('&lt;--right--{(.+?)}--text--{(.+?)}--&gt;', 'gms');
				htmlArray[i] = htmlArray[i].replace(
					regex,
					'<div class="grid-container"><div class="grid-column-6-right">$1</div><div class="grid-column-6-left">$2</div></div>'
				);

				regex = new RegExp('&lt;--gallery--{', 'gm');
				htmlArray[i] = htmlArray[i].replace(regex, '<div class="gallery"><div>');

				regex = new RegExp('}--text----{', 'gm');
				htmlArray[i] = htmlArray[i].replace(regex, '</div><div>');

				regex = new RegExp('}--text--{}--{', 'gm');
				htmlArray[i] = htmlArray[i].replace(regex, '</div><div>');

				regex = new RegExp('}--text--{}--&gt;', 'gm');
				htmlArray[i] = htmlArray[i].replace(regex, '</div></div>');

				regex = new RegExp('}--text--{(.+?)}--{', 'gm');
				htmlArray[i] = htmlArray[i].replace(regex, '<span class="text">$1</span></div><div>');

				regex = new RegExp('}--text--(.+?)--{', 'gm');
				htmlArray[i] = htmlArray[i].replace(regex, '<span class="text">$1</span></div><div>');

				regex = new RegExp('}--text--{(.+?)}--&gt;', 'gm');
				htmlArray[i] = htmlArray[i].replace(regex, '<span class="text">$1</span></div></div>');

				regex = new RegExp('<p>&lt;--product-slider--(.+?)--&gt;</p>', 'gm');
				htmlArray[i] = htmlArray[i].replace(regex, '');

				regex = new RegExp('&lt;--bannerlink--{(.+)}--link--{(.+)}--linktext--{(.+)}--bannertitle--{(.+?)}--&gt;', 'gm');
				htmlArray[i] = htmlArray[i].replace(regex, '<div class="banner"><div class="image-link"><a class="img-link" target="_self" href="$2"><div class="image-wrapper">$1<div class="transparent-wrapper"><div class="overlay-partial"><h2 class="headline">$4</h2><div class="action mbs"><button class="btn btn-primary">$3</button></div></div></div></div></a></div></div>')

			}

			return htmlArray;
		},
		splitContentForGallerySlider(html) {
			if (html.indexOf('<p>&lt;--gallery-slider') >= 0) {
				let subStringsGallerySlider = '';
				subStringsGallerySlider = html.substring(
					html.indexOf('<p>&lt;--gallery-slider'),
					html.indexOf('--&gt;</p>', html.indexOf('<p>&lt;--gallery-slider')) + 10
				);

				if (subStringsGallerySlider !== '') {
					this.splitGallerySliderString(subStringsGallerySlider);
					const splitHtml = html.split(subStringsGallerySlider);

					/* set position for gallery-slider */
					if (html.startsWith('<p>&lt;--gallery-slider--')) {
						/* if slider at the beginn of the html, than index position = 0 */
						this.sliderIndexPositionInHtml = 0;
					} else if (splitHtml !== null && splitHtml.length === 2) {
						this.sliderIndexPositionInHtml = 1;
					} else {
						/* if slider at the end of the html, than index position = 2 */
						this.sliderIndexPositionInHtml = 2;
					}

					return splitHtml;
				}
			}

			return [html];
		},
		splitGallerySliderString(subStringsGallerySlider) {
			const gallerySliderArray = [];

			do {
				const startIndex = subStringsGallerySlider.indexOf('<img');
				if (startIndex >= 0) {
					let endIndex = subStringsGallerySlider.indexOf('--{<img', startIndex);
					if (endIndex < 0) {
						endIndex = subStringsGallerySlider.indexOf('--&gt;', startIndex);
					}
					const subStr = subStringsGallerySlider.substring(startIndex, endIndex);
					gallerySliderArray.push(subStr);
					subStringsGallerySlider = subStringsGallerySlider.replace(subStr, '');
				}
			} while (subStringsGallerySlider.indexOf('<img') >= 0);

			this.fillGallerySliderData(gallerySliderArray);
		},
		fillGallerySliderData(gallerySliderArray) {
			if (gallerySliderArray.length > 0) {
				for (let i = 0; i < gallerySliderArray.length; i++) {
					let itemsMap = {};

					const startIndexSrc = gallerySliderArray[i].indexOf('src=') + 5;
					if (startIndexSrc > 4) {
						const endIndexSrc = gallerySliderArray[i].indexOf('"', startIndexSrc);
						itemsMap['imgsrc'] = gallerySliderArray[i].substring(startIndexSrc, endIndexSrc);
					}

					const startIndexAlt = gallerySliderArray[i].indexOf('alt=') + 5;
					if (startIndexAlt > 4) {
						const endIndexAlt = gallerySliderArray[i].indexOf('"', startIndexAlt);
						itemsMap['imgalt'] = gallerySliderArray[i].substring(startIndexAlt, endIndexAlt);
					}

					const startIndexText = gallerySliderArray[i].indexOf('text--') + 6;
					if (startIndexText > 5) {
						itemsMap['text'] = gallerySliderArray[i].substring(startIndexText);

						/* remove first '{' and last '}' from string if available */
						if (
							itemsMap.text !== null &&
							itemsMap.text !== '' &&
							itemsMap.text.charAt(0) === '{' &&
							itemsMap.text.charAt(itemsMap.text.length - 1) === '}'
						) {
							itemsMap.text = itemsMap.text.substring(1, itemsMap.text.length - 1);
						}
					}
					this.gallerySliderItems.push(itemsMap);
				}
			}
		},
		navigateToSearch() {
			this.$router.push({ name: 'search' });
		},
    getLocalDate(dateString) {
      return new Date(Date.parse(dateString));
    },
	},
};
</script>

<style scoped></style>
