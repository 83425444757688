<template>
    <img src="https://article-vue.s3.eu-central-1.amazonaws.com/img/940x706MissingImageImage.png"
         alt="Image coming soon."
         title="Image coming soon."
         itemprop="image"
         class="centered-image img-article-list"
    />
</template>

<script>
    export default {
        name: "MissingImage"
    }
</script>
