<template>
  <div class="pagination-wrapper" :class="cssClassWrapper">
    <div class="grid-container">
      <!--                        <div id="searchForm" class="column-12">-->
      <!--                            <b-form @submit="onSubmit()">-->
      <!--                                <b-form-input id="searchTerm" v-model="form.searchTerm"></b-form-input>-->
      <!--                                <b-button type="search" variant="primary" class="btnSearch">Suchen</b-button>-->
      <!--                            </b-form>-->
      <!--                        </div>-->

      <div id="sorting" class="column-4 column-xs-12">
        <span class="label">Sortierung:</span>
        <div class="select-wrapper">
          <div class="dropdown-fake">
            <i class="icon-arrow-down"></i>
          </div>
          <b-form-select
              :options="sorts" @change="search(sortQuery)" v-model="sortQuery">
          </b-form-select>
        </div>
      </div>
      <div id="pageSizes" class="column-4 column-xs-6">
        <span class="label">Seitengröße:</span>
        <div class="select-wrapper">
          <div class="dropdown-fake">
            <i class="icon-arrow-down"></i>
          </div>
          <b-form-select
              :options="pageSizes" @change="search(pageSizeQuery)" v-model="pageSizeQuery">
          </b-form-select>
        </div>
      </div>
      <div id="currentSite" class="column-4 column-xs-6">
        <span class="label">Seite(n):</span>
        <div class="site-wrapper">
          <b-button v-for="page in pages" :key="page.name" :pressed="page.selected" variant="function" @click="search(page.query)">
            {{page.name}}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Pagination",
    props: ['cssClassWrapper', 'sorts', 'sortQuery', 'pageSizes', 'pageSizeQuery', 'pages'],
    methods: {
      search(sortQuery){
        this.$parent.search(sortQuery);
      }
    }
  }
</script>
