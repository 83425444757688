<template>
    <div v-if="topicFilter && topicFilter.length > 0" class="topic-wrapper cs-mbxl">
        <h2 class="cs-h2">Themen Magazin</h2>

        <div id="topicSlider" class="topic-slider" :key="topic.name" v-for="topic in topicFilter">
            <VueSlickCarousel v-bind="settings">
                <div :active="value.selected" :key="value.name" v-for="value in topic.values" class="topic-item">
                    <a @click="sendSearch(value.query, null, true, true, {imageUrl: value.bigImage, imageAlt: value.name})" class="topic-link">
                        <div class="image-container">
                            <img v-if="value.thumbnail && value.thumbnail !== ''"
                                    :src="value.thumbnail"
                                    :alt="value.name"
                                    :title="value.name"
                                    class="centered-image image-topic-slider"/>
                            <MissingImage v-else></MissingImage>
                            <span class="topic-name">{{value.name}}</span>
                        </div>
                    </a>
                </div>
            </VueSlickCarousel>
        </div>

    </div>
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel';
    import MissingImage from "./MissingImage";

    export default {
        name: "TopicSlider",
        components: { VueSlickCarousel, MissingImage },
        props: ['filters'],
        data: () => ({
            settings: {
                "infinite":       true,
                "waitForAnimate": false,
                "slidesToShow":   1,
                "slidesToScroll": 1,
                "swipe":          true,
                "variableWidth":  true,
            },
        }),
        computed: {
            topicFilter: function () {
                return this.filters.filter(i => i.name === 'Themen')
            }
        },
        methods:{
            sendSearch(query, newSearchTerm, showBigImage, fromSlider, imageObject) {
                this.$parent.search(query, newSearchTerm, showBigImage, fromSlider, imageObject);
            }
        }
    }
</script>

<style scoped>

</style>
