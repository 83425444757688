import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import VueRouter from 'vue-router';
import BootstrapVue from 'bootstrap-vue';
import App from './App.vue'
import SearchPage from '@/components/SearchPage';
import ArticleDetailsPage from '@/components/ArticleDetailsPage';
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueCustomElement from 'vue-custom-element';
import NProgress from 'nprogress';
import 'document-register-element/build/document-register-element'

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(VueFilterDateParse);
Vue.use(VueFilterDateFormat, {
    monthNames: [
        'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
        'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
    ]
});
Vue.use(VueCustomElement);
Vue.use(NProgress);

const vuexSession = new VuexPersistence({
    key: 'article-vue',
    storage: window.sessionStorage,
});

const store = new Vuex.Store({
    state: {
        companyId: '',
        apiKey: ''
    },
    mutations: {
        setCompanyId(state, companyId) {
            state.companyId = companyId;
        },
        setApiKey(state, apiKey) {
            state.apiKey = apiKey;
        }
    },
    getters: {
        getCompanyId: state => state.companyId,
        getApiKey: state => state.apiKey,
    },
    plugins: [vuexSession.plugin],
})

const routes = [
    { path: '/search', name: "search", component: SearchPage },
    { path: '/article/:id', name: "article", component: ArticleDetailsPage },
    { path: '/search?:filter', name: "filter", component: SearchPage }
    //TODO: redirects
    //{path: '/article/:id, redirect: '/redirectURL'}
];

const router = new VueRouter({
    routes
});

NProgress.configure({
    showSpinner: true,
});

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start()
    }
    next()
})

router.afterEach(() => {
    // Complete the animation of the route progress bar.
    NProgress.done()
})

export default router

App.store = store;
App.router = router;
Vue.customElement('article-vue', App);
