<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
		<path
			fill="currentColor"
			d="M9.525 18.025q-.5.325-1.012.038T8 17.175V6.825q0-.6.513-.888t1.012.038l8.15 5.175q.45.3.45.85t-.45.85l-8.15 5.175Z"
		></path>
	</svg>
</template>

<script>
export default {
	name: 'ArrowDown',
};
</script>

<style scoped>
svg {
	rotate: 90deg;
}
</style>
