<template>
	<div id="app" class="dS-plugin-wrapper">
		<router-view></router-view>
	</div>
</template>

<script>
import _ from 'lodash';

export default {
	name: 'App',
	props: ['comp', 'apikey'],
	created() {
		let comp, key;

		/* there are three options for transmitting the data externally */

		/* first try: get data from props (data-attribute in article-vue-Tag) */
		if (typeof this.comp != 'undefined' && typeof this.apikey != 'undefined') {
			comp = this.comp;
			key = this.apikey;
		}

		/* second try: get data from script (data-attribute in script-Tag) */
		if (comp == null && key == null && document.currentScript) {
			comp = document.currentScript.getAttribute('comp');
			key = document.currentScript.getAttribute('key');
		}

		/* third try: get data from url parameters */
		if (comp == null && key == null) {
			comp = _.get(this.$route, 'query.comp', undefined);
			key = _.get(this.$route, 'query.key', undefined);
		}

		/* console.log("set comp=" + comp);
            console.log("set key=" + key); */

		if (comp != null && key != null) {
			this.$store.commit('setCompanyId', comp);
			this.$store.commit('setApiKey', key);
		}

		/* console.log("get comp=" + this.$store.getters.getCompanyId);
            console.log("get key=" + this.$store.getters.getApiKey); */

		if (this.$route.fullPath.includes('search?')) {
			//in case a link with filters gets provided initially, display it in url bar for later processing
			let decodedInitialPath = decodeURIComponent(this.$route.fullPath.split('?')[1]);
			this.$router.push({
				name: 'filter',
				params: { filter: decodedInitialPath },
			});
		} else if (this.$route.fullPath.includes('/article/')) {
			//call article directly on startup
			let articleId = this.$route.fullPath.split('article/')[1];
			this.$router.push({ name: 'article', params: { id: articleId } });
		} else {
			this.$router.push({
				name: 'search',
			});
		}
	},
};
</script>

<style lang="scss">
@import '../node_modules/nprogress/nprogress.css';
@import './assets/styles/main';

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	padding-top: 20px;
	background-color: var(--ds-color-grey-light);
	font-size: 14px;
	line-height: 24px;
	color: var(--ds-color-grey-dark);
	margin: 0;
}
</style>
