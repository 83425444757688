<template>
	<div class="grid-container">
		<div class="section-one column-4 column-xs-12 column-sm-4">
			<a @click="navigate(article.id)" href="javascript:;">
				<div class="image-box">
					<img
						v-if="article.previewImage && article.previewImage.trim() !== ''"
						:src="article.previewImage"
						:alt="article.headline"
						:title="article.headline"
						itemprop="image"
						class="centered-image img-article-list"
					/>
					<MissingImage v-else></MissingImage>
					<!-- remove sponsored content label - HAG-4969 -->
					<!-- <span v-if="article.brandName && article.brandName.toString().trim() !== ''" class="sponsoredLabel">Sponsored Content</span> -->
				</div>
			</a>
		</div>

		<div class="section-two column-8 column-xs-12 column-sm-8">
			<div v-if="article.createdAt && article.createdAt.trim() !== ''" class="date-wrapper light-text-color cs-mbm">
				<span class="createdAt">
          {{ getLocalDate(article.createdAt) | dateFormat('D. MMMM YYYY') }}
				</span>
			</div>

			<a @click="navigate(article.id)" href="javascript:;" :title="article.headline">
				<h2 v-if="article.headline" class="cs-h2 title cs-mbm" itemprop="name">{{ article.headline }}</h2>
			</a>

			<div class="author-wrapper light-text-color cs-mbm">
				<span v-if="author !== ''" class="author">von</span>
				<span v-if="author !== ''" class="author">&nbsp;{{ author }}</span>
				<span v-if="author !== '' && article.readingTime && article.readingTime !== ''" class="separator">&#124;</span>
				<span v-if="article.readingTime && article.readingTime !== ''" class="readingTime"
					>{{ article.readingTime }}&nbsp;Min. Lesezeit</span
				>
			</div>

			<div class="description">
				<p v-if="article.previewText.trim() !== ''">{{ article.previewText }}</p>
			</div>

			<div class="action-wrapper cs-mtm">
				<a
					@click="navigate(article.id)"
					href="javascript:;"
					:title="article.headline"
					class="btn btn-secondary btnArticleLink"
				>
					Beitrag lesen
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import MissingImage from './MissingImage';

export default {
	name: 'ArticleListItem',
	components: { MissingImage },
	props: ['article'],
	methods: {
		navigate(articleId) {
			this.$router.push({ name: 'article', params: { id: articleId } });
		},
    getLocalDate(dateString) {
      return new Date(Date.parse(dateString));
    },
	},
	computed: {
		author: function() {
			return this.article.author && this.article.author.trim() !== ''
				? this.article.author
				: this.article.brandName && this.article.brandName.toString().trim() !== ''
				? this.article.brandName.toString()
				: '';
		},
	},
};
</script>

<style scoped></style>
