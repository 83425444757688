<template>
	<div class="grid-container" id="searchPage">
		<div v-if="topicHeaderImageSrc !== ''" class="topic-image-wrapper column-12">
			<img
				:src="topicHeaderImageSrc"
				:alt="topicHeaderImageAlt"
				:title="topicHeaderImageAlt"
				class="topic-header-image"
			/>
			<div class="transparent-wrapper">
				<div class="overlay-partial">
					<div class="text-container">
						<span class="h1 topic-headline">{{ topicHeaderImageAlt }}</span>
						<span v-if="topicHeaderImageDesc !== ''" class="description">{{ topicHeaderImageDesc }}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="awsArticleListContent column-9 column-xs-12">
			<h1 class="heading cs-h1 cs-mbl">Unser Ratgeber</h1>

			<div id="articles">
				<div :class="{ 'cs-mbxl': noFilterQuery }" class="articleList firstList">
					<div
						:class="{ hero: article.hero }"
						:key="article.id"
						class="articleListItem cs-mbl"
						v-for="article in articles.slice(0, 1)"
					>
						<ArticleListItem :article="article"></ArticleListItem>
					</div>

					<div
						v-if="Object.keys(this.heroBanner).length !== 0"
						class="cs-heroBanner"
						:id="'heroBanner' + heroBanner.id"
					>
						<h3>{{ heroBanner.name }}</h3>
						<br />
						<a @click="navigate(heroBanner.id)" href="javascript:;">
							<img :src="'https:' + heroBanner.imageUrl" />
						</a>
					</div>

					<div
						:class="{ hero: article.hero }"
						:key="article.id"
						class="articleListItem cs-mbl"
						v-for="article in articles.slice(1, 3)"
					>
						<ArticleListItem :article="article"></ArticleListItem>
					</div>
				</div>

				<TopicSlider v-if="noFilterQuery" :filters="filters"></TopicSlider>

				<div class="articleList secondList cs-mbl">
					<div :key="article.id" class="articleListItem cs-mbl" v-for="article in articles.slice(3)">
						<ArticleListItem :article="article"></ArticleListItem>
					</div>
				</div>
			</div>

			<Pagination
				:cssClassWrapper="['bottom', 'cs-mtx', 'cs-mbl']"
				:sorts="sorts"
				:sortQuery="sortQuery"
				:pageSizes="pageSizes"
				:pageSizeQuery="pageSizeQuery"
				:pages="pages"
			></Pagination>
		</div>
		<div class="awsFilterWrapper column-3 column-xs-12">
			<div class="facet-nav-col">
				<div id="filters">

					<!-- <b-form-group
						:id="'form-group-' + index"
						:key="filter.name"
						:label="filter.name"
						class="cs-mbl"
						v-for="(filter, index) in filters"
					>
						<b-form-checkbox
							:checked="value.selected"
							:key="value.name"
							:name="value.name"
							:options="value.name"
							:value="value.selected"
							@change="onFilterChange(value.query)"
							class="filter-checkbox-container"
							stacked
							v-for="value in filter.values"
							v-model="value.selected"
						>
							{{ value.name }}
						</b-form-checkbox>
					</b-form-group> -->

					<div class="accordion" role="tablist">
						<b-form-group v-for="(filter, index) in filters" :key="filter.name" :id="'form-group-' + index">
							<b-card-header header-tag="div" class="p-1" role="tab">
								<button class="filter-toggle" v-b-toggle="'accordion-' + index" :label="filter.name">
									{{ filter.name }}
									<ArrowDown />
								</button>
							</b-card-header>
							<b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
								<b-card-body>
									<b-form-checkbox
										:checked="value.selected"
										:key="value.name"
										:name="value.name"
										:options="value.name"
										:value="value.selected"
										@change="onFilterChange(value.query)"
										class="filter-checkbox-container"
										stacked
										v-for="value in filter.values"
										v-model="value.selected"
									>
										{{ value.name }}
									</b-form-checkbox>
								</b-card-body>
							</b-collapse>
						</b-form-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import TopicSlider from './TopicSlider';
import ArticleListItem from './ArticleListItem';
import Pagination from './Pagination';
import ArrowDown from './ArrowDown';

const config = require('../../config.js');

export default {
	name: 'SearchPage',
	components: { TopicSlider, ArticleListItem, Pagination, ArrowDown },
	data: () => ({
		articles: [],
		articleText: '',
		pageSizeQuery: '',
		sortQuery: '',
		currentQuery: '',
		form: {
			searchTerm: '',
		},
		pageSizes: [],
		sorts: [],
		filters: [],
		pages: [],
		topicsArray: [],
		topicHeaderImageSrc: '',
		topicHeaderImageAlt: '',
		topicHeaderImageDesc: '',
		noFilterQuery: true,
		currentFilterList: [],
		setList: new Set(),
		heroBanner: {},
	}),
	created() {
		if (this.$route.fullPath.includes('search?')) {
			//in case a link with filters gets provided initially, search and apply the filters
			let params = this.$route.fullPath.split('?')[1];
			this.search(decodeURIComponent(params));
		} else {
			this.search();
		}
	},
	methods: {
		onFilterChange(filterValue) {
			let params = new URLSearchParams(filterValue);
			this.$router.push({
				name: 'filter',
				params: { filter: params },
			});

			this.search(filterValue, undefined, true, false);
		},
		onSubmit() {
			this.search(this.currentQuery, true);
		},
		search(query, newSearchTerm, showBigImage, fromSlider, topicObject) {
			setTimeout(function () {
				window.scrollTo(0, 0);
			}, 600);
			this.articles = [];

			if (!fromSlider && typeof topicObject === 'object') { topicObject = undefined }

			const companyId = this.$store.getters.getCompanyId;
			const apiKey = this.$store.getters.getApiKey;
			const url = `${config.apiURL}/${config.stage}/v1/company/${companyId}/search/articles`;

			const urlParams = new URLSearchParams(query);
			const params = Object.fromEntries(urlParams);
			if (newSearchTerm) {
				params.query = this.form.searchTerm;
			}
			if (showBigImage && this.filters.length > 0 && typeof topicObject !== 'object') {
				let categoryImageFilterSet = new Set();
				for (let i = 0; i < this.filters.length; i++) {
					if (this.filters[i].name === 'Themen') {
						for (let y = 0; y < this.filters[i].values.length; y++) {
							if (this.filters[i].values[y].selected === true) {
								categoryImageFilterSet.add(this.filters[i].values[y]);
							}

							if (this.filters[i].values[y].query === query) {
								if (query.lastIndexOf('topicId') > -1 && query.lastIndexOf('%7') > -1) {
									let id = query.substring(query.lastIndexOf('topicId') + 10, query.lastIndexOf('%7'));
									for (let h = 0; h < this.topicsArray.length; h++) {
										if (this.topicsArray[h].id === id && this.topicsArray[h].description !== '') {
											this.topicHeaderImageDesc = this.topicsArray[h].description;
										}
									}
								}
							}
						}
					}
				}
				
				if (categoryImageFilterSet.size > 0) {
					let categoryImageArray = Array.from(categoryImageFilterSet);
					let categoryImageLastElement = categoryImageArray[categoryImageArray.length - 1];

					if (categoryImageLastElement.bigImage !== '') {
						this.topicHeaderImageSrc = categoryImageLastElement.bigImage;
						this.topicHeaderImageAlt = categoryImageLastElement.name;
					}
				} else {
					this.topicHeaderImageSrc = '';
					this.topicHeaderImageAlt = '';
				}
			} else if (typeof topicObject === 'object') {			
				this.topicHeaderImageSrc = topicObject.imageUrl;
				this.topicHeaderImageAlt = topicObject.imageAlt;
			} else if (this.topicHeaderImageSrc !== '') {
				this.topicHeaderImageSrc = '';
				this.topicHeaderImageAlt = '';
				this.topicHeaderImageDesc = '';
			}

			// hero articles logic
			const noQuery = !params.query;
			const defaultSort = !params.sort || params.sort === 'createdAt+desc';
			const firstPage = !params.start || params.start === '0';
			const hashtagNoFilter = !params.filter;
			if (noQuery && defaultSort && firstPage && hashtagNoFilter) {
				params.getHeroArticles = true;
			}
			if (noQuery && firstPage && hashtagNoFilter) {
				this.noFilterQuery = true;
			} else {
				this.noFilterQuery = false;
			}

			axios
				.get(url, {
					headers: {
						'x-api-key': apiKey,
						'x-access-type': 'storefront',
						encrypted: true,
					},
					params,
				})
				.then((result) => {
					this.articles = result.data.articles;
					this.topicsArray = result.data.topics;
					this.currentQuery = result.data.currentQuery;
					this.processFacets(result.data.facets);

					this.pageSizes = [];
					result.data.pageSizes.forEach((pageSize) => {
						const pageSizeOption = { value: pageSize.query, text: `${pageSize.pageSize} Artikel` };
						this.pageSizes.push(pageSizeOption);
						if (pageSize.selected === true || params.rows == pageSize.pageSize) {
							this.pageSizeQuery = pageSize.query;
						}
					});

					this.sorts = [];
					result.data.sorts.forEach((sort) => {
						const sortOption = { value: sort.query, text: sort.name };
						this.sorts.push(sortOption);
						if (sort.selected === true) {
							this.sortQuery = sort.query;
						}
					});

					this.filters = [];
					result.data.facets.forEach((facet) => {
						const name = facet.name;
						const values = [];
						facet.values.forEach((facetValue) => {
							const facetOption = {
								query: facetValue.query,
								name: facetValue.valueName || facetValue.value,
								selected: facetValue.selected,
							};
							if (facet.field === 'topicId') {
								result.data.topics.forEach((topic) => {
									if (topic.id === facetValue.value) {
										facetOption.thumbnail = topic.image;
										facetOption.bigImage = topic.bigImage;
									}
								});
							}
							let duplicate = false;
							values.forEach((value) => {
								if (value.name === facetOption.name) {
									duplicate = true;
								}
							});
							if (!duplicate) {
								values.push(facetOption);
							}
						});
						this.filters.push({ name, values });
					});

					this.pages = [];
					const paging = result.data.paging;

					const { first, previous, current, next } = paging;
					if (first) {
						this.pages.push(first);
					}
					if (previous) {
						this.pages.push(previous);
					}
					if (current) {
						current.selected = true;
						this.pages.push(current);
					}
					if (next) {
						this.pages.push(next);
					}
				});
		},

		processFacets(facets) {
			facets.forEach((facet) => {
				for (let i = 0; i < facet.values.length; i++) {
					//add selected filters to Set
					if (facet.values[i].selected === true) {
						this.setList.add(facet.field + '::' + facet.values[i].value);
					}

					//remove de-selected values from Set
					this.setList.forEach((entry) => {
						if (facet.values[i].selected === undefined && facet.values[i].value === entry.split('::')[1]) {
							this.setList.delete(entry);
						}
					});
				}
			});

			this.heroBanner = this.processHeroBanners(this.setList);
			if (Object.keys(this.heroBanner).length === 0) {
				this.premiumChannelFallback();
			}
		},

		processHeroBanners(facetSet) {
			let facetValueArray = Array.from(facetSet).reverse();
			this.heroBanner = {};

			for (let i = 0; i < facetValueArray.length; i++) {
				let facetId = facetValueArray[i].split('::')[0];
				let facetValue = facetValueArray[i].split('::')[1];

				if (facetId === 'brandData') {
					for (let j = 0; j < this.articles.length; j++) {
						if (this.articles[j].brandHeroBannerData.length) {
							for (let data of this.articles[j].brandHeroBannerData) {
								if (data.parentId === facetValue) {
									return data;
								}
							}
						}
					}
				}

				if (facetId === 'topicId') {
					for (let j = 0; j < this.articles.length; j++) {
						if (this.articles[j].topicHeroBannerData.length) {
							for (let data of this.articles[j].topicHeroBannerData) {
								if (data.parentId === facetValue) {
									return data;
								}
							}
						}
					}
				}
			}

			return {};
		},

		premiumChannelFallback() {
			this.heroBanner = {};

			for (let i = 0; i < this.articles.length; i++) {
				if (this.articles[i].premiumChannelsHeroBannerData.length) {
					return (this.heroBanner = this.articles[i].premiumChannelsHeroBannerData[0]);
				}
			}
		},

		navigate(articleId) {
			this.$router.push({ name: 'article', params: { id: articleId } });
		},
	},
};
</script>

<style scoped>
#articles {
	min-height: 100vh;
}
.accordion {
	background-color: transparent;
}
.accordion fieldset {
	margin: 0;
}
button.filter-toggle {
	width: -webkit-fill-available;
	padding-left: 0;
	padding-right: 5px;
	color: inherit;
	border-right: none;
	border-left: none;
	border-top: 1px solid var(--ds-color-grey-light-v2);
	border-bottom: 1px solid var(--ds-color-grey-light-v2);
	background: transparent;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.9375rem;
}
button.filter-toggle:hover {
	cursor: pointer;
}

button.filter-toggle.not-collapsed svg {
	rotate: 270deg;
}
</style>
